/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useState, useEffect } from 'react'
import useScript from 'hooks/useScript'
import InnerHTML from 'dangerously-set-html-content'

const JSObject = ({ data }) => {
  const {
    src = 'https://apply.collegeofeab.org/register/',
    type = 'slate',
    id = 'ed7c8a3f-7cfd-4458-b109-3a210dd58df1',
  } = data.json
  const scriptRef = useRef(null)
  const status = useScript(src, scriptRef)

  if (type === 'acquia') {
    return (
      <div ref={scriptRef} id="mauticform" className={type}>
        <h3>Let's Connect</h3>
        {status === 'loading' && 'Loading...'}
        {status === 'error' && 'Error'}
      </div>
    )
  }
  if (type === 'slate') {
    const x = `<div id='form_${id}'>Loading...</div><script>var script = document.createElement("script"); script.async = 1; script.src = "${src}?id=${id}&output=embed&div=form_${id}" + ((location.search.length > 1) ? "&" + location.search.substring(1) : ""); var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(script, s);</script>`
    // uncomment the below for the EAB form
    // const x = `<div id="form_ed7c8a3f-7cfd-4458-b109-3a210dd58df1">Loading...</div><script>/*<![CDATA[*/var script = document.createElement('script'); script.async = 1; script.src = 'https://apply.collegeofeab.org/register/?id=ed7c8a3f-7cfd-4458-b109-3a210dd58df1&output=embed&div=form_ed7c8a3f-7cfd-4458-b109-3a210dd58df1' + ((location.search.length > 1) ? '&' + location.search.substring(1) : ''); var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(script, s);/*]]>*/</script>`

    return (
      <div id="connect" className={type}>
        <h3>
          Don't wait to start your journey at Lindenwood. Connect with a{' '}
          <span className="white-space">counselor today!</span>
        </h3>
        <InnerHTML html={x} />
      </div>
    )
  }
}

export default JSObject
