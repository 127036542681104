/* eslint-disable react/no-danger */
import React from 'react'

const Headline = ({ data }) => {
  if (!data) return null
  return <h2 className="headline">{data}</h2>
}

const Paragraph = ({ data }) => {
  if (!data) return null
  return <div className="text-section" dangerouslySetInnerHTML={{ __html: data.childMarkdownRemark.html }} />
}

function ParagraphWithHeadline({ data }) {
  const { paragraph, paragraphHeadline, cssClass } = data
  return (
    <div className={`paragraph-with-headline ${cssClass}`}>
      <Headline data={paragraphHeadline} />
      <Paragraph data={paragraph} />
    </div>
  )
}

export default ParagraphWithHeadline
