import Container from 'components/common/Container'
import React from 'react'
import getLandingPageModules from 'utils/getLandingPageModules'

function Section({ data, index }) {
  const contentfulType = data.cssClass
  let type = 'wrapper'
  let constraints = 'full'

  if (contentfulType.includes('wrapper')) {
    type = 'wrapper'
  } else if (contentfulType.includes('group')) {
    type = 'group'
  } else if (contentfulType.includes('article')) {
    type = 'article'
  } else if (contentfulType.includes('section')) {
    type = 'section'
  }

  if (contentfulType.includes('narrow')) {
    constraints = 'narrow'
  } else {
    constraints = 'full'
  }

  return (
    <Container type={type} constraints={constraints} className={`${data.cssClass}`}>
      {data.sections &&
        data.sections.map((module, i) => module.internal && getLandingPageModules(module, `${index}.${i}`))}
    </Container>
  )
}

export default Section
