import React from 'react'
import Card from '../Card'

function ContentfulComponentCard({ data }) {
  const { image, description, text, cardLinkUrl } = data
  const { childMarkdownRemark: descriptionText } = description || {} // Handle null description
  const { childMarkdownRemark: title } = text || {} // Handle null text

  // Add conditional checks for image, description, and cardLinkUrl
  const imageUrl = image && image.image ? image.image : null
  const descriptionHtml = descriptionText && descriptionText.html ? descriptionText.html : null
  const linkUrl = cardLinkUrl && cardLinkUrl !== '' ? cardLinkUrl : null

  return (
    <Card
      title={title ? title.html : null} // Handle null title
      description={descriptionHtml}
      image={imageUrl}
      cardLinkUrl={linkUrl}
    />
  )
}

export default ContentfulComponentCard
