/* eslint-disable react/no-danger */
import React from 'react'
import styled from '@emotion/styled'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const Title = ({ data }) => {
  if (!data) return null
  return <div className="card-title" dangerouslySetInnerHTML={{ __html: data }} />
}

const Description = ({ data }) => {
  if (!data) return null
  return <div className="card-description" dangerouslySetInnerHTML={{ __html: data }} />
}

const Card = ({ title, description, image, cardLinkUrl }) => {
  if (!cardLinkUrl) {
    return (
      <div className="card-container">
        {image && <Img image={image.gatsbyImageData} alt={image.title || 'Untitled Image'} />}
        <div className="text-container">
          <Title data={title} />
          <Description data={description} />
        </div>
      </div>
    )
  }

  return (
    <div className="card-container">
      <a href={cardLinkUrl}>
        {image && <Img image={image.gatsbyImageData} alt={image.title || 'Untitled Image'} />}
        <div className="text-container">
          <Title data={title} />
          <Description data={description} />
        </div>
      </a>
    </div>
  )
}

export default Card
