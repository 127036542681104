// import React from 'react'

// Note: This is a hacky way to scroll to the anchor link. It's not ideal, but it works for now.
export const scrollTo = () => {
  const anchorLink = document.querySelector('#mauticform')

  anchorLink.classList.add('highlight')

  if (anchorLink) {
    const offset = anchorLink.getBoundingClientRect().top + window.pageYOffset
    window.scrollTo({ top: offset - 7 * 16, behavior: 'smooth' })
    window.history.pushState({}, '', '#mauticform')
  }
}
